import { Box } from "@chakra-ui/layout"
import { ProductContentProps } from "../.."
import { DecayImage } from "./DecayImage"
import { useRef, useState } from "react"
import { useRect } from "../../../../hooks/useRect"
import { ImageFragment } from "../../../../shopify/sdk"
import { useQuery } from "react-query"
import {
  useCarousel,
  CarouselContainer,
  Carousel,
  CarouselImage,
} from "../../../../components/Carousel"

const loadImage = (src: string) =>
  new Promise<HTMLImageElement>(resolve => {
    const image = new Image()
    image.crossOrigin = "anonymous"
    image.onload = () => resolve(image)
    image.src = src
  })

const selectSrc = (image: ImageFragment, container: DOMRect): string => {
  if (!image.width || !image.height) return image.src2400
  const scale = Math.max(
    image.width / container.width,
    image.height / container.height
  )
  const targetWidth = container.width * scale
  if (targetWidth <= 400) return image.src400
  if (targetWidth <= 800) return image.src800
  if (targetWidth <= 1200) return image.src1200
  if (targetWidth <= 1600) return image.src1600
  return image.src2400
}

type DecayCarouselProps = {
  carousel: Carousel
  onError?: (error: Error) => void
}
const DecayCarousel = ({ carousel, onError }: DecayCarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const containerRect = useRect(containerRef)

  const currentImageData = carousel.images[carousel.index]

  const currentSrc = selectSrc(currentImageData, containerRect)

  const placeholders = useQuery(
    ["placeholders", ...carousel.images.map(image => image.id)],
    () =>
      Promise.all(carousel.images.map(image => loadImage(image.placeholder)))
  )
  const { data: currentImage } = useQuery(["image", currentSrc], () =>
    loadImage(currentSrc)
  )

  const placeholder = placeholders.data
    ? placeholders.data[carousel.index]
    : null

  const image = currentImage || placeholder
  return (
    <Box ref={containerRef} layerStyle="fill">
      <DecayImage
        image={image}
        onError={onError}
        paused={carousel.showThumbnails}
      />
    </Box>
  )
}

export const Decay = ({ product }: ProductContentProps) => {
  const images = product.images.edges.map(({ node: image }) => image)
  const carousel = useCarousel(images)
  const [glError, setGlError] = useState<Error | null>(null)
  return (
    <CarouselContainer carousel={carousel}>
      {glError ? (
        <CarouselImage carousel={carousel} />
      ) : (
        <DecayCarousel carousel={carousel} onError={setGlError} />
      )}
    </CarouselContainer>
  )
}
