import { Box, Stack } from "@chakra-ui/react"
import { ReactNode } from "react"
import { HTML } from "../../components/HTML"
import { ProductFragment } from "../../shopify/sdk"
import { BuyButton } from "./BuyButton"
import { useWindowSize } from "../../hooks/useWindowSize"
import assert from "assert-ts"

type ProductLayoutProps = { product: ProductFragment; children: ReactNode }
export const ProductLayout = ({ product, children }: ProductLayoutProps) => {
  const windowSize = useWindowSize()
  return (
    <>
      <Box
        height="100%"
        maxHeight={`calc(${windowSize[1]}px - (var(--space-4) + 0.8rem))`}
      >
        {children}
      </Box>
      <Box
        minHeight="50vh"
        onClick={e => {
          assert(
            e.target instanceof Element && e.currentTarget instanceof Element
          )
          if (e.target.tagName === "A" || e.target.tagName === "BUTTON") return
          if (window.scrollY > 0) return
          e.currentTarget.scrollIntoView({ behavior: "smooth" })
        }}
      >
        <Stack
          direction={["column", null, "row"]}
          paddingX="pageMargin"
          paddingTop="0.4rem"
          paddingBottom={8}
          alignItems="flex-start"
          spacing={[4, null, 0]}
        >
          <Stack width={["100%", null, "50%"]} spacing={4}>
            {product.variants.edges.map(({ node: variant }) => (
              <BuyButton
                key={variant.id}
                variant={variant}
                label={
                  product.variants.edges.length === 1
                    ? "Add to Cart"
                    : undefined
                }
              />
            ))}
          </Stack>
          <HTML width={["100%", null, "50%"]}>{product.descriptionHtml}</HTML>
        </Stack>
      </Box>
    </>
  )
}
