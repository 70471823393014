import React, { createContext, ReactNode, useContext, useMemo } from "react"
import { GraphQLClient } from "graphql-request"
import { getSdk, Sdk } from "../../shopify/sdk"
import { CheckoutProvider } from "./CheckoutProvider"

const ShopifyContext = createContext<Sdk | null>(null)

export const useShopify = () => {
  const sdk = useContext(ShopifyContext)
  if (sdk === null)
    throw new Error("useShopify called outside of ShopifyContext")
  return sdk
}

type ShopifyProviderProps = {
  shopifyUrl: string
  storefrontAccessToken: string
  children: ReactNode
}
export const ShopifyProvider = ({
  shopifyUrl,
  storefrontAccessToken,
  children,
}: ShopifyProviderProps) => {
  const sdk = useMemo(() => {
    const client = new GraphQLClient(`https://${shopifyUrl}/api/graphql`, {
      headers: {
        "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
      },
    })
    return getSdk(client)
  }, [shopifyUrl, storefrontAccessToken])
  return (
    <ShopifyContext.Provider value={sdk}>
      <CheckoutProvider>{children}</CheckoutProvider>
    </ShopifyContext.Provider>
  )
}
