import React, { ComponentType, useLayoutEffect } from "react"
import { useParams } from "react-router"
import { useQuery } from "react-query"
import { useShopify } from "../../components/ShopifyProvider"
import { ProductFragment } from "../../shopify/sdk"
import { ProductLayout } from "./ProductLayout"
import { Decay } from "./Interventions/Decay"

const interventions: Record<string, ComponentType<ProductContentProps>> = {
  decay: Decay,
}

export type ProductContentProps = { product: ProductFragment }
const ProductContent = ({ product }: ProductContentProps) => {
  const backgroundColor = product.background_color!.value
  const intervention = product.intervention!.value

  const Intervention = interventions[intervention]

  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      "--background-color",
      backgroundColor
    )
    return () => {
      document.documentElement.style.setProperty("--background-color", null)
    }
  }, [backgroundColor])

  return (
    <ProductLayout product={product}>
      {Intervention && <Intervention product={product} />}
    </ProductLayout>
  )
}

export const Product = () => {
  const { handle } = useParams()
  if (handle === undefined) throw new Error("No handle")

  const sdk = useShopify()
  const { data } = useQuery(`product_${handle}`, () => sdk.product({ handle }))

  const product = data?.productByHandle

  if (!product) return null

  return <ProductContent product={product} />
}
