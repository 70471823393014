import React, { ReactNode } from "react"
import { Logo } from "../Logo"
import { Menu } from "../Menu"

type LayoutProps = { children: ReactNode }
export const Layout = ({ children }: LayoutProps) => (
  <>
    {children}
    <Menu />
    <Logo />
  </>
)
