import React, { ReactNode, useEffect, useState } from "react"
import { useShopify } from "../ShopifyProvider"
import { useQuery } from "react-query"
import { Box, BoxProps, Stack, StackProps } from "@chakra-ui/react"
import { Link } from "../Link"
import { matchPath, useLocation, useMatch } from "react-router"
import { useCheckout } from "../ShopifyProvider/CheckoutProvider"

const isTouch = "ontouchstart" in window

type MenuItemProps = BoxProps & {
  expanded?: boolean
  active?: boolean
  url?: string
}
const MenuItem = ({
  expanded = true,
  active = false,
  url,
  children,
  ...props
}: MenuItemProps) => {
  const [animateIn, setAnimateIn] = useState(false)
  useEffect(() => {
    setTimeout(() => setAnimateIn(true), 100)
  }, [])
  return (
    <Box
      height={expanded && animateIn ? 4 : 0}
      transition="height 0.5s"
      overflow="hidden"
      textTransform="uppercase"
      display="inline-block"
      {...props}
    >
      <Box
        position="absolute"
        top="0.08em"
        bottom="0.08em"
        width="100%"
        zIndex="-1"
        backgroundColor={active ? "background" : "transparent"}
      />
      {url ? <Link to={url}>{children}</Link> : children}
    </Box>
  )
}

const Indent = (props: StackProps) => (
  <Stack spacing={0} paddingLeft={[2, null, 4]} {...props} />
)

export const Menu = () => {
  const sdk = useShopify()
  const location = useLocation()
  const matchesCurrentPath = (path: string) =>
    matchPath({ path, end: false }, location.pathname) !== null

  const isProducts = matchesCurrentPath("/products")
  const [mouseOver, setMouseOver] = useState(false)
  const [toggleProducts, setToggleProducts] = useState(isProducts)
  const showProducts = isProducts || toggleProducts || mouseOver
  useEffect(() => {
    if (!matchPath({ path: "/products" }, location.pathname))
      setToggleProducts(false)
  }, [location.pathname])

  const { data } = useQuery("menu", () => sdk.menu())
  const checkout = useCheckout()
  const cartItems = checkout?.lineItems.edges.reduce(
    (total, { node: lineItem }) => total + lineItem.quantity,
    0
  )
  if (!data) return null
  return (
    <Stack
      position="fixed"
      top="pageMargin"
      left="pageMargin"
      marginTop="-0.2em"
      zIndex={100}
      spacing={0}
      onMouseEnter={!isTouch ? () => setMouseOver(true) : undefined}
      onMouseLeave={!isTouch ? () => setMouseOver(false) : undefined}
    >
      <MenuItem>{process.env.REACT_APP_SITE_URL!}</MenuItem>
      <Indent>
        <MenuItem
          onClick={
            isTouch ? () => setToggleProducts(!toggleProducts) : undefined
          }
        >
          /products
        </MenuItem>
        <Indent>
          {data.products.edges.map(({ node: product }) => {
            const url = `/products/${product.handle}`
            return (
              <MenuItem
                url={url}
                active={matchesCurrentPath(url)}
                expanded={showProducts}
                key={product.handle}
              >
                /{product.handle}
              </MenuItem>
            )
          })}
        </Indent>
        <MenuItem
          url="/information"
          active={matchesCurrentPath("/information")}
        >
          /information
        </MenuItem>
        <MenuItem url="/cart" active={matchesCurrentPath("/cart")}>
          /cart {cartItems !== undefined ? `[${cartItems}]` : ""}
        </MenuItem>
      </Indent>
    </Stack>
  )
}

// const isTouch = "ontouchstart" in window

// type LinkProps = {
//   path: string
//   isLink?: boolean
//   label: string
//   children?: LinkProps[]
// }
// type MenuItemProps = React.ComponentProps<typeof Box> & {
//   link: LinkProps
// }
// const MenuItem = ({
//   link: { path, isLink = true, label, children },
//   ...props
// }: MenuItemProps) => {
//   const isCurrentUrl = useMatch({ path: path || "/no", end: false })
//   console.log(path, isCurrentUrl)
//   const [animateIn, setAnimateIn] = useState(false)
//   useEffect(() => {
//     setTimeout(() => setAnimateIn(true), 100)
//   }, [])

//   return (
//     <Stack spacing={0} alignItems="flex-start" {...props}>
//       <Box
//         height={animateIn ? 4 : 0}
//         transition="height 0.5s"
//         overflow="hidden"
//         textTransform="uppercase"
//         display="inline-block"
//       >
//         <Box
//           position="absolute"
//           top="0.08em"
//           bottom="0.08em"
//           width="100%"
//           zIndex="-1"
//           backgroundColor={
//             isCurrentUrl && isLink ? "background" : "transparent"
//           }
//         />
//         {path ? <Link to={path}>{label}</Link> : label}
//       </Box>
//       {children && children.length > 0 && (
//         <Box paddingLeft={[2, null, 4]}>
//           {children.map(link => (
//             <MenuItem key={link.path} link={link} />
//           ))}
//         </Box>
//       )}
//     </Stack>
//   )
// }

// export const Menu = () => {
//   const sdk = useShopify()
//   const { data } = useQuery("menu", () => sdk.menu())
//   const checkout = useCheckout()
//   const cartItems = checkout?.lineItems.edges.reduce(
//     (total, { node: lineItem }) => total + lineItem.quantity,
//     0
//   )
//   const [isMenuExpanded, setIsMenuExpanded] = useState(false)
//   if (!data) return null
//   const root: LinkProps = {
//     label: process.env.REACT_APP_SITE_URL!,
//     path: "/",
//     isLink: false,
//     children: [
//       ...data.pages.edges
//         .filter(({ node: page }) => page.handle === "information")
//         .map(({ node: page }) => ({
//           path: page.handle,
//           label: `/${page.handle}`,
//         })),
//       {
//         label: "/products",
//         path: "/products",
//         isLink: false,
//         children: data.products.edges.map(({ node: product }) => ({
//           path: `/products/${product.handle}`,
//           label: `/${product.handle}`,
//         })),
//       },
//       {
//         label: `/cart${cartItems !== undefined ? `[${cartItems}]` : ""}`,
//         path: "/cart",
//       },
//     ],
//   }
//   return (
//     <MenuItem
//       position="fixed"
//       top="pageMargin"
//       left="pageMargin"
//       marginTop="-0.2em"
//       zIndex={100}
//       link={root}
//       onMouseEnter={!isTouch ? () => setIsMenuExpanded(true) : undefined}
//       onMouseLeave={!isTouch ? () => setIsMenuExpanded(false) : undefined}
//     />
//   )
// }
