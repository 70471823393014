import React, { ComponentProps, useLayoutEffect } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { useShopify } from "../../components/ShopifyProvider"
import { HTML } from "../../components/HTML"
import { PageLayout } from "../../components/PageLayout"
import { useToken } from "@chakra-ui/system"
import { Link } from "../../components/Link"
import { HStack, Stack, StackProps } from "@chakra-ui/layout"

const PageNavigationLink = (props: ComponentProps<typeof Link>) => (
  <Link
    textStyle="small"
    textTransform="uppercase"
    whiteSpace="nowrap"
    lineHeight={1}
    {...props}
  />
)

const pageHandles = ["privacy-policy", "shipping-and-returns", "terms"]
const PageNavigation = (props: StackProps) => {
  const sdk = useShopify()
  const { data } = useQuery("menu", () => sdk.menu())
  if (!data) return null
  return (
    <HStack
      zIndex={10}
      position="sticky"
      bottom={0}
      justifyContent="center"
      background="background"
      padding="pageMargin"
      spacing={[1, 2, 4]}
      width="100%"
      {...props}
    >
      {data.pages.edges
        .filter(({ node: page }) => pageHandles.includes(page.handle))
        .map(({ node: page }) => (
          <PageNavigationLink to={`/${page.handle}`} key={page.handle}>
            {page.title}
          </PageNavigationLink>
        ))}
      {process.env.REACT_APP_INSTAGRAM_URL && (
        <PageNavigationLink
          to={process.env.REACT_APP_INSTAGRAM_URL}
          target="_blank"
        >
          Instagram
        </PageNavigationLink>
      )}
    </HStack>
  )
}

export const Page = () => {
  const { handle } = useParams()
  if (handle === undefined) throw new Error("No handle")

  const sdk = useShopify()
  const { data } = useQuery(`page_${handle}`, () => sdk.page({ handle }))
  const page = data?.pageByHandle

  const backgroundColor = useToken("colors", "red")

  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      "--background-color",
      backgroundColor
    )
    return () => {
      document.documentElement.style.setProperty("--background-color", null)
    }
  }, [backgroundColor])

  if (!page) return null

  return (
    <PageLayout>
      <Stack height="100%">
        <HTML flexGrow={1} padding="pageMargin">
          {page.body}
        </HTML>
        <PageNavigation />
      </Stack>
    </PageLayout>
  )
}
