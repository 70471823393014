import { createContext, ReactNode, useContext, useEffect } from "react"
import {
  CheckoutFragment,
  CheckoutLineItemsAddMutationVariables,
  CheckoutLineItemsAddMutation,
  CheckoutLineItemsUpdateMutationVariables,
  CheckoutLineItemsUpdateMutation,
  CheckoutLineItemsRemoveMutationVariables,
  CheckoutLineItemsRemoveMutation,
} from "../../shopify/sdk"
import { useQuery, useMutation, useQueryClient } from "react-query"
import useLocalStorage from "react-use-localstorage"
import { useShopify } from "./ShopifyProvider"

const CheckoutContext = createContext<CheckoutFragment | null>(null)

export const useCheckout = () => useContext(CheckoutContext)

const useCheckoutMutation = <Variables extends { checkoutId: string }, Result>(
  mutationFn: (variables: Variables) => Promise<Result>
) => {
  const queryClient = useQueryClient()
  const checkoutId = useCheckout()?.id
  const mutation = useMutation(
    (variables: Omit<Variables, "checkoutId">) => {
      if (!checkoutId) throw new Error("Can't mutate checkout, no checkout")
      return mutationFn({ checkoutId, ...variables } as Variables)
    },
    { onSuccess: () => queryClient.invalidateQueries("checkout") }
  )
  return checkoutId ? mutation : null
}

export const useAddLineItem = () => {
  const sdk = useShopify()
  return useCheckoutMutation<
    CheckoutLineItemsAddMutationVariables,
    CheckoutLineItemsAddMutation
  >(variables => sdk.checkoutLineItemsAdd(variables))
}

export const useUpdateLineItems = () => {
  const sdk = useShopify()
  return useCheckoutMutation<
    CheckoutLineItemsUpdateMutationVariables,
    CheckoutLineItemsUpdateMutation
  >(variables => sdk.checkoutLineItemsUpdate(variables))
}

export const useRemoveLineItems = () => {
  const sdk = useShopify()
  return useCheckoutMutation<
    CheckoutLineItemsRemoveMutationVariables,
    CheckoutLineItemsRemoveMutation
  >(variables => sdk.checkoutLineItemsRemove(variables))
}

type CheckoutProviderProps = { children: ReactNode }
export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const queryClient = useQueryClient()
  const sdk = useShopify()

  const [savedCheckoutId, setSavedCheckoutId] = useLocalStorage(
    "shopifyCheckout",
    ""
  )
  const checkoutQuery = useQuery(
    ["checkout", savedCheckoutId],
    () => sdk.checkout({ id: savedCheckoutId }),
    { enabled: savedCheckoutId !== "" }
  )
  const checkout =
    checkoutQuery.data?.node?.__typename === "Checkout"
      ? checkoutQuery.data?.node
      : null

  const createCheckoutMutation = useMutation(
    () => sdk.checkoutCreate({ input: {} as any }),
    {
      onSuccess: data => {
        const id = data.checkoutCreate?.checkout?.id
        if (id) setSavedCheckoutId(id)
        queryClient.invalidateQueries("checkout")
      },
    }
  )

  useEffect(() => {
    let shouldCreateNewCheckout =
      savedCheckoutId === "" ||
      (checkoutQuery.status === "success" &&
        (checkout === null || checkout.completedAt))
    if (shouldCreateNewCheckout && createCheckoutMutation.status === "idle") {
      createCheckoutMutation.mutate()
    }
  }, [checkout, checkoutQuery.status, createCheckoutMutation, savedCheckoutId])

  return (
    <CheckoutContext.Provider value={checkout}>
      {children}
    </CheckoutContext.Provider>
  )
}
