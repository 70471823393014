import { createPingpong } from "./pingpong"
import createTexture from "gl-texture2d"
import createShader from "gl-shader"
import { createTriangle } from "./triangle"
import mapFragment from "./mapFragment.glsl"
import imageFragment from "./imageFragment.glsl"
import vertex from "./vertex.glsl"
import { resizeObserver } from "../../../../utils/observer"
import { Spring } from "wobble"
import Stats from "stats.js"

var stats = new Stats()
stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild(stats.dom)

type Texture = ReturnType<typeof createTexture>

const config = {
  noiseScale: 5,
  noiseScrollX: 0,
  noiseScrollY: 0,
  noiseSpeed: 0.2,
  distanceThreshold: 0.4,
  show: "result",
  resetOnMove: true,
}

// const gui = new GUI()
// gui.add(config, "noiseScale").min(0.1).max(10)
// gui.add(config, "noiseSpeed").min(0).max(1).step(0.001)
// gui.add(config, "push").min(0).max(0.01).step(0.001)
// export const idleChangeListeners: ((v: boolean) => void)[] = []
// gui
//   .add(config, "resetOnMove")
//   .onChange(v => idleChangeListeners.forEach(fn => fn(v)))

export const createRenderer = (canvas: HTMLCanvasElement) => {
  const gl = canvas.getContext("webgl")
  if (gl === null) throw new Error("Failed to initialise webGL context")

  const shader = createShader(gl, vertex, imageFragment)
  gl.getExtension("OES_texture_float")
  gl.getExtension("OES_texture_float_linear")
  gl.getExtension("OES_texture_half_float")
  gl.getExtension("OES_texture_half_float_linear")

  let texture: Texture
  let needsInit = true
  let needsUpdate = true
  let disposed = false
  const startTime = Date.now() - Math.random() * 1000000
  let time = startTime

  const triangle = createTriangle(gl)
  triangle.bind()
  const pingpong = createPingpong(gl, [1, 1], mapFragment)
  pingpong.magFilter = pingpong.minFilter = gl.LINEAR

  const draw = () => {
    if (!texture) return
    // console.time("map draw")
    pingpong.bind({
      resolution: pingpong.shape,
      time: (Date.now() - startTime) / 1000,
      init: needsInit,
      noiseScale: config.noiseScale,
      noiseScrollX: config.noiseScrollX,
      noiseScrollY: config.noiseScrollY,
      noiseSpeed: config.noiseSpeed,
      showNoise: config.show === "noise" ? 1 : 0,
      strength: strengthSpring.currentValue,
    })
    triangle.draw()
    const map = pingpong.unbind()
    // console.timeEnd("map draw")
    // console.time("image draw")
    shader.bind()
    Object.assign(shader.uniforms, {
      map: map.bind(0),
      image: texture.bind(1),
      resolution: [gl.drawingBufferWidth, gl.drawingBufferHeight],
      texSize: texture.shape,
      distanceThreshold: config.distanceThreshold,
      showMap: config.show === "result" ? 0 : 1,
      strength: strengthSpring.currentValue,
    })
    triangle.draw()
    // console.timeEnd("image draw")
    needsInit = false
  }

  let frameID: number | null = null
  const tick = () => {
    if (needsUpdate || strengthSpring.currentValue > 0) draw()
    needsUpdate = false
    frameID = requestAnimationFrame(tick)
  }

  const strengthSpring = new Spring({ fromValue: 0, overshootClamping: true })

  const resize = () => {
    if (disposed) return
    const rect = canvas.getBoundingClientRect()
    if (rect.width === 0 || rect.height === 0) return
    const imagePixelRatio = Math.min(window.devicePixelRatio, 2)
    canvas.width = Math.floor(rect.width * imagePixelRatio)
    canvas.height = Math.floor(rect.height * imagePixelRatio)
    gl.viewport(0, 0, canvas.width, canvas.height)
    const mapPixelRatio = 0.5
    pingpong.shape = [
      Math.floor(rect.width * mapPixelRatio),
      Math.floor(rect.height * mapPixelRatio),
    ]
    pingpong.magFilter = pingpong.minFilter = gl.LINEAR

    needsInit = true
    draw()
  }
  resize()
  resizeObserver.observe(canvas, resize)

  return {
    start: tick,
    stop: () => {
      if (frameID) cancelAnimationFrame(frameID)
    },
    setImage: (image: HTMLImageElement) => {
      if (texture) texture.dispose()
      texture = createTexture(gl, image)
      texture.magFilter = texture.minFilter = gl.LINEAR
      needsUpdate = true
    },
    setStrength: (strength: 1 | 0) => {
      if (strength === 1) {
        strengthSpring.updateConfig({ toValue: 1, stiffness: 0.5 }).start()
      } else {
        strengthSpring.updateConfig({ toValue: 0, stiffness: 50 }).start()
      }
    },
    dispose: () => {
      if (texture) texture.dispose()
      pingpong.dispose()
      resizeObserver.unobserve(canvas, resize)
      disposed = true
      if (frameID !== null) cancelAnimationFrame(frameID)
    },
  }
}

export type Renderer = ReturnType<typeof createRenderer>
