import React, { useCallback, useRef, useState } from "react"
import { Box, BoxProps, SimpleGrid } from "@chakra-ui/layout"
import { ImageFragment } from "../../shopify/sdk"
import assert from "assert-ts"
import { ShopifyImage } from "../Image"
import { Breakpoint } from "../Breakpoint"
import {
  AspectRatio,
  Stack,
  transition,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useWindowSize } from "../../hooks/useWindowSize"
import { useDelayedExit } from "../../hooks/useDelayedExit"

const isTouch = "ontouchstart" in window

export const useCarousel = (images: ImageFragment[]) => {
  const windowSize = useWindowSize()
  const windowIsLandscape = windowSize[0] > windowSize[1]
  const selectedImages = images.filter(image => {
    const imageIsLandscape = image.width! > image.height!
    return imageIsLandscape === windowIsLandscape
  })
  const [index, setIndex] = useState(0)
  if (index > selectedImages.length) {
    setIndex(0)
  }
  const [showThumbnails, setShowThumbnails] = useState(false)
  const numImages = selectedImages.length
  const prev = useCallback(
    () => setIndex(index => (index - 1 + numImages) % numImages),
    [numImages]
  )
  const next = useCallback(
    () => setIndex(index => (index + 1) % numImages),
    [numImages]
  )
  return {
    index,
    setIndex,
    images: selectedImages,
    prev,
    next,
    showThumbnails,
    setShowThumbnails,
  }
}
export type Carousel = ReturnType<typeof useCarousel>

type CarouselCursorProps = { carousel: Carousel }
export const CarouselCursor = ({ carousel }: CarouselCursorProps) => {
  const cursorRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)
  return (
    <Box
      layerStyle="fill"
      onMouseLeave={() => setVisible(false)}
      onMouseMove={e => {
        setVisible(true)
        const cursor = cursorRef.current
        if (!cursor) throw new Error("Ref not set")
        cursor.style.transform = `translate(${e.nativeEvent.offsetX}px, ${e.nativeEvent.offsetY}px)`
      }}
      cursor="none"
    >
      <Box
        position="absolute"
        pointerEvents="none"
        top={0}
        left={0}
        ref={cursorRef}
        opacity={visible ? 1 : 0}
        transition="opacity .2s"
        userSelect="none"
      >
        {carousel.index + 1} of {carousel.images.length}
      </Box>
    </Box>
  )
}

type CarouselThumbnailsProps = { carousel: Carousel }
const CarouselThumbnails = ({ carousel }: CarouselThumbnailsProps) => {
  const columns = useBreakpointValue([5, 7, 9]) || 5
  const firstRowImages = carousel.images.length % columns
  const leftoverColumns = columns - firstRowImages
  const maxRatio = carousel.images.reduce(
    (max, image) => Math.max(max, image.height! / image.width!),
    0
  )
  const thumbnails = [
    ...carousel.images.slice(0, firstRowImages),
    ...Array.from({ length: leftoverColumns }).map(_ => null),
    ...carousel.images.slice(firstRowImages),
  ]
  const [renderThumbails, transitionIn] = useDelayedExit(
    carousel.showThumbnails,
    200
  )
  return (
    <>
      {renderThumbails && (
        <Stack
          layerStyle="fill"
          background="white"
          display={["none", "flex"]}
          flexDirection="column"
          justifyContent="flex-end"
          padding="pageMargin"
          onClick={e => e.stopPropagation()}
          opacity={transitionIn ? 1 : 0}
          transition="opacity 200ms"
        >
          <SimpleGrid columns={columns} spacing={2} paddingBottom={6}>
            {thumbnails.map((image, i) => (
              <Box key={i}>
                <div style={{ paddingBottom: maxRatio * 100 + "%" }} />
                {image !== null ? (
                  <ShopifyImage
                    layerStyle="fill"
                    width="100%"
                    image={image}
                    objectFit="contain"
                    objectPosition="top left"
                    onClick={() => {
                      carousel.setShowThumbnails(false)
                      carousel.setIndex(carousel.images.indexOf(image))
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                    cursor="pointer"
                  />
                ) : (
                  <span />
                )}
              </Box>
            ))}
          </SimpleGrid>
          {/* <Box
            height={}={1}
            textTransform="uppercase"
            onClick={() => carousel.setShowThumbnails(false)}
            cursor="pointer"
            userSelect="none"
          >
            Back
          </Box> */}
        </Stack>
      )}
      <Box
        position="absolute"
        bottom={0}
        left={0}
        padding="pageMargin"
        display={["none", "block"]}
        lineHeight={1}
        textTransform="uppercase"
        onClick={e => {
          e.stopPropagation()
          carousel.setShowThumbnails(!carousel.showThumbnails)
        }}
        cursor="pointer"
        userSelect="none"
      >
        {carousel.showThumbnails ? "Back" : "View all"}
      </Box>
    </>
  )
}

type CarouselImageProps = { carousel: Carousel }
export const CarouselImage = ({ carousel }: CarouselImageProps) => (
  <ShopifyImage
    layerStyle="fill"
    objectFit="cover"
    image={carousel.images[carousel.index]}
  />
)

type CarouselContainerProps = React.ComponentProps<typeof Box> & {
  carousel: Carousel
}
export const CarouselContainer = ({
  carousel,
  children,
  ...props
}: CarouselContainerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { next, prev } = carousel
  return (
    <Box
      ref={ref}
      layerStyle="fill"
      onClick={e => {
        assert(e.currentTarget instanceof Element)
        e.clientX > e.currentTarget.clientWidth / 2 ? next() : prev()
      }}
      {...props}
    >
      {children}
      {!isTouch && <CarouselCursor carousel={carousel} />}
      {isTouch && (
        <Box
          padding="pageMargin"
          position="absolute"
          lineHeight={1}
          marginTop="-0.2em"
          top={0}
          right={0}
        >
          {carousel.index + 1} of {carousel.images.length}
        </Box>
      )}
      <Breakpoint min={1}>
        <CarouselThumbnails carousel={carousel} />
      </Breakpoint>
    </Box>
  )
}
